import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { graphql } from 'gatsby';

import SEO from '../components/SEO';
import { colors, font } from '../consts/style';
import breakpoints from '../consts/breakpoints';
import { Container, Inner } from '../components/Elements';
import mq from '../style/mq';

const Wrapper = styled.div`
  background: ${colors.dark};
  color: white;
  ${Inner} {
    background: ${colors.dark};
    width: 77.3rem;
    max-width: 100%;
    padding: 6rem 0;
  }
`;

const Banner = styled.div`
  height: 60rem;
  position: relative;
  ${mq.tabletSmall`
     height: 43rem;
  `}
`;
const BannerImg = styled(Img)`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 0;
  height: 60rem;
  & > img {
    object-fit: 'cover' !important;
    object-position: '50% 50%' !important;
  }

  @media screen and (max-width: ${breakpoints.tabletSmall}px) {
    height: 43rem;
  }
`;

const Featured = styled.div`
  margin-bottom: 2.4rem;
  span {
    ${font.p};
    text-transform: uppercase;
    padding: 0.4rem;
    background: ${colors.teal};
    &.mr {
      margin-right: 4rem;
      font-weight: bold;
    }
  }
  p {
    margin: 0;
  }
`;

const Title = styled.h1`
  ${font.h1};
  margin-bottom: 1.2rem;
`;

const Short = styled.p`
  ${font.p};
  font-weight: bold;
  margin-bottom: 2.4rem;
`;

const FeateredImg = styled(Img)`
  margin-bottom: 2.4rem;
`;

const Content = styled.div`
  p {
    ${font.p};
    margin-bottom: 1.6rem;
  }
  h1 {
    ${font.h1};
    margin-bottom: 1.6rem;
  }
  h2 {
    ${font.h2};
    margin-bottom: 1.6rem;
  }
  h3 {
    ${font.h3};
    margin-bottom: 1.6rem;
  }
  h4 {
    ${font.h4};
    margin-bottom: 1.6rem;
  }
  a {
    color: white;
    text-decoration: underline;
    font-weight: bold;
    &:hover {
      color: ${colors.yellow};
    }
  }
  img {
    max-width: 100%;
    margin-bottom: 1.6rem;
  }

  ul,
  ol {
    ${font.p};
    margin-bottom: 1.6rem;
  }

  blockquote {
    background: rgba(255, 255, 255, 0.1);
    padding: 2.4rem;
    border-left: 0.8rem solid ${colors.yellow};
    margin-bottom: 1.6rem;
    p {
      ${font.pLarge};
      margin: 0;
    }
  }
`;

export default function BlogPost({ data: { blog } }) {
  return (
    <Fragment>
      <SEO meta={blog.seoMetaTags} />
      <Wrapper>
        <Banner>
          <BannerImg
            backgroundColor={colors.dark}
            fluid={blog.bannerImage.fluid}
          />
        </Banner>
        <Container>
          <Inner>
            <Featured>
              <span>
                {blog.featuredIn && (
                  <span className="mr">Featured in {blog.featuredIn}</span>
                )}
                <span>{blog.meta.firstPublishedAt}</span>
              </span>
            </Featured>
            <Title>{blog.title}</Title>
            <Short>{blog.shortDescription}</Short>
            <FeateredImg
              fluid={blog.featuredImage.fluid}
              backgroundColor={colors.teal}
            />
            <Content dangerouslySetInnerHTML={{ __html: blog.postContent }} />
          </Inner>
        </Container>
      </Wrapper>
    </Fragment>
  );
}

BlogPost.propTypes = {
  data: PropTypes.object.isRequired,
};

export const blogQuery = graphql`
  query($slug: String!) {
    blog: datoCmsBlogPost(slug: { eq: $slug }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      slug
      meta {
        firstPublishedAt(formatString: "MM/YYYY")
      }
      featuredIn
      postContent
      shortDescription
      featuredImage {
        fluid(maxWidth: 800, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid_noBase64
        }
      }
      bannerImage {
        fluid(maxWidth: 1800, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid_noBase64
        }
      }
    }
  }
`;
